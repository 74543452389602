import * as React from 'react';
import { FormGroup, Label } from './styles/common';

export function FormInputPrice({
  label, onChange, name, error, valid, type = 'text', placeholder, value,
}) {
  const inputRef = React.useRef(null);

  // Remove all non-numeric characters except commas
  const numericValue = value.replace(/[^\d,]/g, '');
  const formattedValue = numericValue
    ? `${Number(numericValue.replace(/,/g, '')).toLocaleString()} €`
    : '';

  const handleInputChange = (e) => {
    const rawValue = e.target.value.replace(/[^\d]/g, ''); // Remove all non-numeric characters
    onChange(rawValue); // Pass only the numeric part without formatting
  };

  const handleKeyUp = (e) => {
    const { selectionStart } = e.target;

    if (inputRef.current) {
      const newCursorPos = Math.min(selectionStart, inputRef.current.value.length - 2); // Keep cursor inside valid range
      setTimeout(() => {
        inputRef.current.setSelectionRange(newCursorPos, newCursorPos);
      }, 0); // Wait for DOM updates to complete
    }
  };

  return (
    <FormGroup>
      {label !== undefined && (
        <Label>
          {label}
          {' '}
          (€)
        </Label>
      )}
      <input
        ref={inputRef}
        placeholder={placeholder}
        className={`form-control text-center ${error ? 'is-invalid' : ''} ${
          valid ? 'is-valid' : ''
        }`}
        type={type}
        value={formattedValue}
        name={name}
        onChange={handleInputChange}
        onKeyUp={handleKeyUp}
      />
      <div className="invalid-feedback">{error}</div>
    </FormGroup>
  );
}
