import React from 'react';
import { FormInputText } from '../../../common/formInputs/FormInputText';
import { FormInputSelect } from '../../../common/formInputs/FormInputSelect';
import { commercialCountry } from '../../../common/constants';

function CompanyExtendedGroupStructure({
  errors, isValid, register, answers,
}) {
  return (
    <>
      <h4 className="text-center mt-3">Struttura del Gruppo Estesa</h4>
      <div className="row mt-5">
        {answers.extended_group_structure.map((groupStructure, index) => (
          <div className="col-12 col-md-6 mb-4" key={index}>
            <div className="card border">
              <div className="card-body">
                <FormInputText
                  placeholder="Nome della Società"
                  label="Nome della Società"
                  error={errors.company_name?.message}
                  valid={isValid(`extended_group_structure.${index}.company_name`)}
                  registration={register(`extended_group_structure.${index}.company_name`)}
                />
                <FormInputSelect
                  label="Stato"
                  placeholder="-Seleziona-"
                  options={commercialCountry}
                  error={errors.country?.message}
                  valid={isValid(`extended_group_structure.${index}.country`)}
                  registration={register(`extended_group_structure.${index}.country`)}
                />
                <FormInputText
                  placeholder="Livello"
                  label="Livello"
                  error={errors.level?.message}
                  valid={isValid(`extended_group_structure.${index}.level`)}
                  registration={register(`extended_group_structure.${index}.level`)}
                />
              </div>
            </div>
          </div>
        ))}

      </div>
    </>
  );
}

export default CompanyExtendedGroupStructure;
