import axe from '../../services/axios-client/axiosClient';
import managementAxe from '../../services/axios-client/managementServisAxeClient';
import caller from './insuranceRequestsSlice';
import quotationsAxe from '../../services/axios-client/quotationsAxeClient';
import { confirmAlert, generalAlertError, generalAlertSuccessToast } from '../../common/utils/alerts';
import { quotationTypes } from '../../common/constants';

const serverError = 'Si è verificato un problema con il server';

export const listInsuranceRequests = async (page, search) => {
  caller.listInsuranceRequestsPending();
  try {
    const res = await managementAxe.get(
      '/broker/quotes/insurance-requests',
      {
        params: {
          ...search,
          page,
        },
      },
    );

    if (res.status !== 200) {
      caller.listInsuranceRequestsReject('Errore Interno');
      return;
    }
    caller.listInsuranceRequestsSuccess(res.data);
  } catch (error) {
    caller.listInsuranceRequestsReject('Errore Interno');
  }
};

export const resetListInsuranceRequests = () => {
  caller.insuranceRequestsReset();
};

export const getMetlifeQuotesData = async (requestToken) => {
  const { data: res } = await quotationsAxe.get(
    `/results/quotes/infortuni/${requestToken}`,
  );

  caller.metlifeQuotesDataUpdated({
    loading: false,
    customer: {
      name: res.data.customer.name,
      surname: res.data.customer.surname,
      gender: res.data.customer.gender,
      fiscal_code: res.data.customer.fiscal_code,
      phone: res.data.customer.phone,
      email: res.data.customer.email,
      date_of_birth: res.data.customer.date_of_birth,
      country_of_birth_code: res.data.customer.country_of_birth_code,
      province_of_birth_code: res.data.customer.province_of_birth_code,
      commune_of_birth_code: res.data.customer.commune_of_birth_code,
      born_abroad: res.data.customer.born_abroad,
      residence_province_code: res.data.customer.residence_province_code,
      residence_commune_code: res.data.customer.residence_commune_code,
      postal_code: res.data.customer.postal_code,
      address: res.data.customer.address,
      house_number: res.data.customer.house_number,
      civil_status_id: res.data.customer.civil_status_id,
      education_level_id: res.data.customer.education_level_id,
      profession_id: res.data.customer.profession_id,
      province: res.data.customer.province,
    },
    quote: {
      request_id: res.data.quote.request_id,
      pack_type: res.data.quote.infortuni.pack_type,
      company: res.data.quote.infortuni.company,
      plan: res.data.quote.infortuni.plan,
      name: res.data.quote.name,
      dc: res.data.quote.decesso,
      ip: res.data.quote.invalidita_permanente,
      diaria_ricovero: res.data.quote.diaria_ricovero,
      diaria_gesso: res.data.quote.diaria_gesso,
      premio_annuo: res.data.quote.premio_annuo,
      ulc: res.data.quote.ulc,
      ulc_premio_aggiuntivo:
        res.data.quote.ulc !== null ? res.data.quote.ulc_premio_aggiuntivo : 0,
      rsm: res.data.quote.rsm,
      rsm_premio_aggiuntivo:
        res.data.quote.rsm !== null ? res.data.quote.rsm_premio_aggiuntivo : 0,
      is_aquista: res.data.quote.is_aquista,
    },
    saved: res.data.quote.is_saved === 1,
    quoteId: res.data.quote.quotation_id,
    insuranceRequest: res.data.insurance_request,
  });
};

export const saveInfortuniQuotation = async (quotationId) => {
  caller.metlifeQuotesDataUpdated({
    isSaving: true,
  });

  await axe.post('/quote-save', {
    quotation_id: quotationId,
  });

  caller.metlifeQuotesDataUpdated({
    isSaving: false,
    saved: true,
  });
};

export const updateInfortuniExtensions = (value) => {
  caller.infortuniQuotesUpdateExtensions(value);
};

export const addInfortuniExtension = async (request_id, extensions) => {
  const data = {
    ulc: '',
    rsm: '',
    request_id,
  };

  if (extensions.includes('ustioni')) {
    data.ulc = 1;
  } else data.ulc = 0;
  if (extensions.includes('rimborso')) {
    data.rsm = 1;
  } else data.rsm = 0;

  caller.metlifeQuotesDataUpdated({
    loading: true,
  });

  const { data: res } = await quotationsAxe.put(
    '/guarantees/infortuni/metlife/update-quote',
    data,
  );

  caller.metlifeQuotesDataUpdated({
    quote: {
      request_id: res.data.request_id,
      pack_type: res.data.pack_type,
      company: res.data.company,
      plan: res.data.plan,
      name: res.data.name,
      dc: res.data.decesso,
      ip: res.data.invalidita_permanente,
      diaria_ricovero: res.data.diaria_ricovero,
      diaria_gesso: res.data.diaria_gesso,
      premio_annuo: res.data.premio_annuo,
      ulc: res.data.ulc,
      ulc_premio_aggiuntivo:
        res.data.ulc !== null ? res.data.ulc_premio_aggiuntivo : 0,
      rsm: res.data.rsm,
      rsm_premio_aggiuntivo:
        res.data.rsm !== null ? res.data.rsm_premio_aggiuntivo : 0,
    },
    loading: false,
  });
};

export const removeInfortuniExtension = async (quote, value) => {
  const data = {
    ulc: quote.ulc !== null ? 1 : 0,
    rsm: quote.rsm !== null ? 1 : 0,
    request_id: quote.request_id,
  };

  if (value === 'ustioni') {
    data.ulc = 0;
  }
  if (value === 'rimborso') {
    data.rsm = 0;
  }

  caller.metlifeQuotesDataUpdated({
    loading: true,
  });

  const { data: res } = await quotationsAxe.post(
    '/infortuni/metlife/update-quote',
    data,
  );

  caller.metlifeQuotesDataUpdated({
    quote: {
      request_id: res.data.request_id,
      pack_type: res.data.pack_type,
      company: res.data.company,
      plan: res.data.plan,
      name: res.data.name,
      dc: res.data.decesso,
      ip: res.data.invalidita_permanente,
      diaria_ricovero: res.data.diaria_ricovero,
      diaria_gesso: res.data.diaria_gesso,
      premio_annuo: res.data.premio_annuo,
      ulc: res.data.ulc,
      ulc_premio_aggiuntivo:
        res.data.ulc !== null ? res.data.ulc_premio_aggiuntivo : 0,
      rsm: res.data.rsm,
      rsm_premio_aggiuntivo:
        res.data.rsm !== null ? res.data.rsm_premio_aggiuntivo : 0,
    },
    loading: false,
  });
};

export const resetInfortuniQuotes = () => {
  caller.infortuniQuotesReset();
};

export const toggleCoveragesAction = (coverageId) => {
  caller.autoQuotesToggleCoverages(coverageId);
};

export const toggleMotoCoveragesAction = (coverageId) => {
  caller.motoQuotesToggleCoverages(coverageId);
};

export const updateGuaranteesAutoAction = async (
  requestToken,
  coverages,
  quoteId,
) => {
  if (quoteId !== undefined || quoteId !== null) {
    await quotationsAxe.post(
      `/guarantees/vehicle/${requestToken}/update-guarantees`,
      {
        coverages,
      },
      {
        params: {
          quoteId,
        },
      },
    );
  } else {
    await quotationsAxe.post(
      `/guarantees/vehicle/${requestToken}/update-guarantees`,
      {
        coverages,
      },
    );
  }

  const res = await quotationsAxe.get(
    `/results/quotes/vehicle/${requestToken}`,
  );
  if (res.status === 200) {
    caller.autoQuotesRequestInfoUpdated(res.data.data);
  }
};

export const updateGuaranteesMotoAction = async (
  requestToken,
  coverages,
  quoteId,
) => {
  if (quoteId !== undefined || quoteId !== null) {
    await quotationsAxe.post(
      `/guarantees/moto/${requestToken}/update-guarantees`,
      {
        coverages,
      },
      {
        params: {
          quoteId,
        },
      },
    );
  } else {
    await quotationsAxe.post(
      `/guarantees/moto/${requestToken}/update-guarantees`,
      {
        coverages,
      },
    );
  }

  const res = await quotationsAxe.get(
    `/results/quotes/moto/${requestToken}`,
  );
  if (res.status === 200) {
    caller.motoQuotesRequestInfoUpdated(res.data.data);
  }
};

export const loadAutoQuotes = async (requestToken) => {
  const res = await quotationsAxe.get(
    `/results/quotes/vehicle/${requestToken}`,
  );

  if (res.status !== 200) {
    caller.autoQuotesRequestInfoRejected(serverError);
  }

  if (res.status === 200) {
    if (res.data.data.quotations[0].coverages === '') {
      caller.autoQuotesCoveragesSet([]);
    } else {
      caller.autoQuotesCoveragesSet(
        res.data.data.quotations[0].coverages.split(','),
      );
    }

    caller.autoQuotesRequestInfoUpdated(res.data.data);
  }
};

export const addQuoteAutoToCart = async (quoteId, callback, modifyData, closeButton = null, addDiscount = null) => {
  caller.autoQuotesDataUpdated({
    addingQuote: true,
  });
  try {
    if (addDiscount !== null) {
      addDiscount();
    }

    const response = await axe.get(`/quotations/save/${quoteId}`);

    if (response.data.error === 'insurance_in_process') {
      if (closeButton !== null) {
        closeButton();
      }
      confirmAlert('Non è possibile modificare, vuoi che ricalcoliamo?', () => modifyData(), () => { }, 'Aviso!');
      caller.autoQuotesDataUpdated({
        addingQuote: false,
        loading: false,
      });
      return;
    }

    if (response.status !== 200) {
      const error_message = response?.data?.error?.message;

      generalAlertError(error_message || 'La quotazione non e stata aggiunta! Riprova.');
      caller.autoQuotesDataUpdated({
        addingQuote: false,
      });
      return;
    }
    caller.autoQuotesDataUpdated({
      addingQuote: false,
    });

    callback();

    if (closeButton !== null) {
      closeButton();
    }
  } catch (error) {
    generalAlertError('Errore Interno.');
    caller.autoQuotesDataUpdated({
      addingQuote: false,
    });
  }
};

export const removeQuoteAutoFromCart = async (quoteId, callback, modifyData) => {
  caller.autoQuotesDataUpdated({
    loading: true,
  });
  try {
    const response = await axe.get(`/quotations/remove/${quoteId}`);

    if (response.data.error === 'insurance_in_process') {
      confirmAlert('Non è possibile modificare, vuoi che ricalcoliamo?', () => modifyData(), () => { }, 'Aviso!');
      caller.autoQuotesDataUpdated({
        addingQuote: false,
        loading: false,
      });
      return;
    }

    if (response.status !== 200) {
      generalAlertError('La quotazione non e stata rimossa! Riprova.');
      caller.autoQuotesDataUpdated({
        loading: false,
      });
      return;
    }
    caller.autoQuotesDataUpdated({
      loading: false,
    });

    callback();
  } catch (error) {
    generalAlertError('Errore Interno.');
    caller.autoQuotesDataUpdated({
      loading: false,
    });
  }
};

export const loadMotoQuotes = async (requestToken) => {
  const res = await quotationsAxe.get(
    `/results/quotes/moto/${requestToken}`,
  );

  if (res.status !== 200) {
    caller.motoQuotesRequestInfoRejected(serverError);
    return;
  }

  if (res.status === 200) {
    if (res.data.data.quotations[0].coverages === '') {
      caller.motoQuotesCoveragesSet([]);
    } else {
      caller.motoQuotesCoveragesSet(
        res.data.data.quotations[0].coverages.split(','),
      );
    }

    caller.motoQuotesRequestInfoUpdated(res.data.data);
  }
};

export const addQuoteMotoToCart = async (quoteId, callback, modifyData) => {
  caller.motoQuotesDataUpdated({
    addingQuote: true,
  });
  try {
    const response = await axe.get(`/quotations/save/${quoteId}`);

    if (response.data.error === 'insurance_in_process') {
      const dismissButton = document.getElementById('buttonClose');

      // Trigger a click event on the button
      if (dismissButton) {
        dismissButton.click();
      }
      confirmAlert('Non è possibile modificare, vuoi che ricalcoliamo?', () => modifyData(), () => { }, 'Aviso!');
      caller.motoQuotesDataUpdated({
        addingQuote: false,
        loading: false,
      });
      return;
    }

    if (response.status !== 200) {
      generalAlertError('La quotazione non e stata aggiunta! Riprova.');
      caller.motoQuotesDataUpdated({
        addingQuote: false,
      });
      return;
    }
    caller.motoQuotesDataUpdated({
      addingQuote: false,
    });

    callback();

    const dismissButton = document.getElementById('buttonClose');

    // Trigger a click event on the button
    if (dismissButton) {
      dismissButton.click();
    }
  } catch (error) {
    generalAlertError('Errore Interno.');
    caller.motoQuotesDataUpdated({
      addingQuote: false,
    });
  }
};

export const removeQuoteMotoFromCart = async (quoteId, callback, modifyData) => {
  caller.motoQuotesDataUpdated({
    addingQuote: true,
  });
  try {
    const response = await axe.get(`/quotations/remove/${quoteId}`);

    if (response.data.error === 'insurance_in_process') {
      confirmAlert('Non è possibile modificare, vuoi che ricalcoliamo?', () => modifyData(), () => { }, 'Aviso!');
      caller.motoQuotesDataUpdated({
        addingQuote: false,
        loading: false,
      });
      return;
    }

    if (response.status !== 200) {
      generalAlertError('La quotazione non e stata aggiunta! Riprova.');
      caller.motoQuotesDataUpdated({
        addingQuote: false,
      });
      return;
    }
    caller.motoQuotesDataUpdated({
      addingQuote: false,
    });

    callback();
  } catch (error) {
    generalAlertError('Errore Interno.');
    caller.motoQuotesDataUpdated({
      addingQuote: false,
    });
  }
};

export const updateAutoQuotesData = (requestInfo, sortBy, preventivas_quotes, quotes) => {
  const { quotations, failed_quotations } = requestInfo;
  let companyIds = [];
  const sortedQuotes = [...quotations];
  const preventivasQuotesSorted = [...preventivas_quotes];
  let toSortQuotes = { ...quotes };
  quotations.forEach((quote) => {
    companyIds.push(quote.company_id);
  });
  failed_quotations.forEach((quote) => {
    companyIds.push(quote.company_id);
  });

  companyIds = companyIds.filter((v, i, a) => a.indexOf(v) === i);

  const toUpdate = {};

  if (quotes === null) {
    toUpdate.quotations = null;
  }
  if (quotations.length === 0) {
    toUpdate.quotes = null;
    toUpdate.noQuotes = true;
  } else {
    if (sortBy === 'amount') {
      for (let x = 0; x < quotationTypes.length; x++) {
        const item = quotationTypes[x];
        let arr = [];
        if (item.id === 'res_civile' && toSortQuotes[item.id] && toSortQuotes[item.id] !== undefined) {
          arr = [...toSortQuotes[item.id]];
          arr.sort((a, b) => a.amount - b.amount);
          toSortQuotes = {
            [item.id]: arr,
          };
        }
      }
      sortedQuotes.sort((a, b) => a.amount - b.amount);
    } else {
      for (let x = 0; x < quotationTypes.length; x++) {
        const item = quotationTypes[x];
        let arr = [];
        if (item.id === 'res_civile' && toSortQuotes[item.id] && toSortQuotes[item.id] !== undefined) {
          arr = [...toSortQuotes[item.id]];
          arr.sort((a, b) => {
            if (a.company_quality && b.company_quality) {
              return a.company_quality.coefficiente - b.company_quality.coefficiente;
            }
            if (!a.company_quality && b.company_quality) {
              return 1;
            } if (a.company_quality && !b.company_quality) {
              return -1;
            }

            if (a.preventivas_company_quality && b.preventivas_company_quality) {
              return a.preventivas_company_quality.coefficiente - b.preventivas_company_quality.coefficiente;
            }
            if (!a.preventivas_company_quality && b.preventivas_company_quality) {
              return 1;
            } if (a.preventivas_company_quality && !b.preventivas_company_quality) {
              return -1;
            }
            return 0;
          });
          toSortQuotes = {
            [item.id]: arr,
          };
        }
      }

      sortedQuotes.sort((a, b) => {
        if (a.company_quality && b.company_quality) {
          return a.company_quality.coefficiente - b.company_quality.coefficiente;
        }
        if (!a.company_quality && b.company_quality) {
          return 1;
        } if (a.company_quality && !b.company_quality) {
          return -1;
        }

        if (a.preventivas_company_quality && b.preventivas_company_quality) {
          return a.preventivas_company_quality.coefficiente - b.preventivas_company_quality.coefficiente;
        }
        if (!a.preventivas_company_quality && b.preventivas_company_quality) {
          return 1;
        } if (a.preventivas_company_quality && !b.preventivas_company_quality) {
          return -1;
        }
        return 0;
      });
    }
    toUpdate.quotes = sortedQuotes;
    toUpdate.noQuotes = false;
    toUpdate.quotations = { ...quotes, res_civile: toSortQuotes.res_civile };
  }

  if (preventivasQuotesSorted.length === 0) {
    toUpdate.preventivas_quotes = [];
  } else {
    toUpdate.preventivas_quotes = preventivasQuotesSorted;
    if (sortBy === 'amount') {
      preventivasQuotesSorted.sort((a, b) => a.amount - b.amount);
      toUpdate.preventivas_quotes = preventivasQuotesSorted;
    } else {
      preventivasQuotesSorted.sort((a, b) => {
        if (a.preventivas_company_quality && b.preventivas_company_quality) {
          return a.preventivas_company_quality.coefficiente - b.preventivas_company_quality.coefficiente;
        }
        if (!a.preventivas_company_quality && b.preventivas_company_quality) {
          return 1;
        } if (a.preventivas_company_quality && !b.preventivas_company_quality) {
          return -1;
        }
        return 0;
      });

      toUpdate.preventivas_quotes = preventivasQuotesSorted;
    }
  }

  toUpdate.failedQuotes = failed_quotations;

  caller.autoQuotesDataUpdated(toUpdate);

  const activeBrands = [1, 2];

  let clearTimer = false;

  if (companyIds.length === activeBrands.length) {
    clearTimer = true;
  }

  return clearTimer;
};

export const updateSortByAuto = (sortBy) => {
  caller.autoQuotesDataUpdated({
    sortBy,
  });
};
export const updateSortByCommercial = (sortBy) => {
  caller.commercialQuotesDataUpdated({
    sortBy,
  });
};
export const fetchAutoReport = async (requestId) => {
  caller.autoQuotesDataUpdated({
    loadingReport: true,
  });
  try {
    const response = await managementAxe.get(`/report/report_auto/${requestId}`);
    if (response.status !== 200) {
      caller.autoQuotesDataUpdated({
        loadingReport: false,
        error: serverError,
      });
    }

    caller.autoQuotesDataUpdated({
      loadingReport: false,
      report: response.data,
    });
  } catch (error) {
    caller.autoQuotesDataUpdated({
      loadingReport: false,
      error: serverError,
    });
  }
};

export const updateMotoQuotesData = (requestInfo) => {
  const { quotations, failed_quotations } = requestInfo;
  let companyIds = [];

  quotations.forEach((quote) => {
    companyIds.push(quote.company_id);
  });

  failed_quotations.forEach((quote) => {
    companyIds.push(quote.company_id);
  });

  companyIds = companyIds.filter((v, i, a) => a.indexOf(v) === i);

  const toUpdate = {};

  if (quotations.length === 0) {
    toUpdate.quotes = null;
    toUpdate.noQuotes = true;
  } else {
    toUpdate.quotes = quotations;
    toUpdate.noQuotes = false;
  }

  toUpdate.failedQuotes = failed_quotations;

  caller.motoQuotesDataUpdated(toUpdate);

  const activeBrands = [1, 2];

  let clearTimer = false;

  if (companyIds.length === activeBrands.length) {
    clearTimer = true;
  }

  return clearTimer;
};

export const infodriveUpdateMarkupResultPage = async (quoteId, percentage) => {
  caller.autoQuotesDataUpdated({
    loading: true,
  });

  await axe.post(`/infodrive/quote/update-markup/${quoteId}`, {
    markup_percentage: percentage,
  });

  caller.autoQuotesDataUpdated({
    loading: false,
  });
};

export const generateInfodriveQuoteResultPage = async (request_token) => {
  caller.autoQuotesDataUpdated({
    loading: true,
  });

  const res = await quotationsAxe.post(
    `/quotations/infodrive/quote/${request_token}`,
  );

  if (res.status === 400) {
    generalAlertError('Non puoi aggiungere questo prodotto in questo momento!');
  }

  await loadAutoQuotes(request_token);
};

export const resetAutoQuotes = () => {
  caller.autoQuotesReset();
};

export const resetMotoQuotes = () => {
  caller.motoQuotesReset();
};

export const professionQuotesSaveQuote = async (quoteId) => {
  caller.professionQuotesDataUpdated({
    isSaving: true,
  });
  await axe.post('/quote-save', {
    quotation_id: quoteId,
  });
  caller.professionQuotesDataUpdated({
    isSaving: false,
    saved: true,
  });
};

export const professionQuotesGetQuotes = async (requestToken) => {
  const response = await quotationsAxe.get(
    `/results/quotes/profession/${requestToken}`,
  );

  if (response.status !== 200) {
    caller.professionQuotesDataUpdatedRejected(serverError);
    return;
  }

  caller.professionQuotesDataUpdated({
    loading: false,
    quoteId: response.data.data.quotations[0].id,
    profession: response.data.data.profession,
    userData: response.data.data,
    extensions: response.data.data.profession.extensions,
    selling_point: response.data.data.selling_point,
    distance_token: response.data.data.distance_token,
    customer: response.data.data.customer,
    quotations: response.data.data.quotes,
    processingQuote: response.data.data.processingQuote,
    riskQuotations: response.data.data.risk_quotations,
    error: null,
  });
};

export const addQuoteToCart = async (quoteId, callback, modifyData) => {
  caller.professionQuotesDataUpdated({
    addingQuote: true,
  });
  try {
    const response = await axe.get(`/quotations/save/${quoteId}`);

    if (response.data.error === 'insurance_in_process') {
      const dismissButton = document.getElementById('buttonClose');

      // Trigger a click event on the button
      if (dismissButton) {
        dismissButton.click();
      }

      confirmAlert('Non è possibile modificare, vuoi che ricalcoliamo?', () => modifyData(), () => { }, 'Aviso!');
      caller.professionQuotesDataUpdated({
        addingQuote: false,
        loading: false,
      });
      return;
    }

    if (response.status !== 200) {
      generalAlertError('La quotazione non e stata aggiunta! Riprova.');
      caller.professionQuotesDataUpdated({
        addingQuote: false,
      });
      return;
    }
    caller.professionQuotesDataUpdated({
      addingQuote: false,
    });

    callback();

    const dismissButton = document.getElementById('buttonClose');

    // Trigger a click event on the button
    if (dismissButton) {
      dismissButton.click();
    }
  } catch (error) {
    generalAlertError('Errore Interno.');
    caller.professionQuotesDataUpdated({
      addingQuote: false,
    });
  }
};

export const removeQuoteFromCart = async (quoteId, callback, modifyData) => {
  caller.professionQuotesDataUpdated({
    addingQuote: true,
  });
  try {
    const response = await axe.get(`/quotations/remove/${quoteId}`);

    if (response.data.error === 'insurance_in_process') {
      confirmAlert('Non è possibile modificare, vuoi che ricalcoliamo?', () => modifyData(), () => { }, 'Aviso!');
      caller.professionQuotesDataUpdated({
        addingQuote: false,
        loading: false,
      });
      return;
    }

    if (response.status !== 200) {
      generalAlertError('La quotazione non e stata rimossa! Riprova.');
      caller.professionQuotesDataUpdated({
        addingQuote: false,
      });
      return;
    }
    caller.professionQuotesDataUpdated({
      addingQuote: false,
    });

    callback();
  } catch (error) {
    generalAlertError('Errore Interno.');
    caller.professionQuotesDataUpdated({
      addingQuote: false,
    });
  }
};

export const professionAddExtension = async (
  requestToken,
  extensions,
  callback,
) => {
  await quotationsAxe.put(`/guarantees/profession/${requestToken}/update`, {
    extensions: extensions.join(','),
  });
  callback();
};

export const resetProfessionQuotes = () => {
  caller.professionQuotesReset();
};

export const infodriveGetQuote = async (requestToken) => {
  const res = await quotationsAxe.get(
    `/results/quotes/infodrive/${requestToken}`,
  );
  caller.infodriveQuotesDataUpdated({
    insuranceRequest: res.data.data.insuranceRequest,
    quote: res.data.data.quote,
    loading: false,
    markupPercentage: res.data.data.quote.markup_percentage,
    failedQuotation: res.data.data.failedQuotation,
  });
};

export const infodriveUpdateMarkup = async (
  quoteId,
  percentage,
  successCallback,
) => {
  caller.infodriveQuotesDataUpdated({
    loading: true,
  });

  const res = await quotationsAxe.put(
    `/guarantees/infodrive/update-markup/${quoteId}`,
    {
      markup_percentage: percentage,
    },
  );

  if (res.status === 200) {
    successCallback();
  } else {
    caller.infodriveQuotesDataUpdated({
      loading: false,
    });
  }
};

export const infodriveQuotesSaveQuote = async (quoteId) => {
  caller.infodriveQuotesDataUpdated({
    isSaving: true,
  });
  await axe.post('/quote-save', {
    quotation_id: quoteId,
  });
  caller.infodriveQuotesDataUpdated({
    isSaving: false,
    saved: true,
  });
};

export const resetInfodriveQuotes = () => {
  caller.infodriveQuotesReset();
};

/**
 * Travel Quotes
 */

export const loadTravelQuotes = async (requestToken) => {
  const res = await quotationsAxe.get(`/results/quotes/travel/${requestToken}`);

  caller.travelQuotesDataUpdated({
    insuranceRequest: res.data.insuranceRequest,
    quotations: res.data.quotations,
    failedQuotations: res.data.failed_quotations,
    customer: res.data.customer,
    quotes: res.data.quotes,
    processingQuote: res.data.processingQuote,
    riskQuotations: res.data.risk_quotations,
    loading: false,
  });
};

export const addQuoteTravelToCart = async (quoteId, callback, modifyData) => {
  caller.travelQuotesDataUpdated({
    addingQuote: true,
  });
  try {
    const response = await axe.get(`/quotations/save/${quoteId}`);

    if (response.data.error === 'insurance_in_process') {
      const dismissButton = document.getElementById('buttonClose');
      // Trigger a click event on the button
      if (dismissButton) {
        dismissButton.click();
      }

      confirmAlert('Non è possibile modificare, vuoi che ricalcoliamo?', () => modifyData(), () => { }, 'Aviso!');
      caller.travelQuotesDataUpdated({
        addingQuote: false,
        loading: false,
      });
      return;
    }

    if (response.status !== 200) {
      generalAlertError('La quotazione non e stata aggiunta! Riprova.');
      caller.travelQuotesDataUpdated({
        addingQuote: false,
      });
      return;
    }
    caller.travelQuotesDataUpdated({
      addingQuote: false,
    });

    callback();

    const dismissButton = document.getElementById('buttonClose');

    // Trigger a click event on the button
    if (dismissButton) {
      dismissButton.click();
    }
  } catch (error) {
    generalAlertError('Errore Interno.');
    caller.travelQuotesDataUpdated({
      addingQuote: false,
    });
  }
};

export const removeQuoteTravelFromCart = async (quoteId, callback, modifyData) => {
  caller.travelQuotesDataUpdated({
    addingQuote: true,
  });
  try {
    const response = await axe.get(`/quotations/remove/${quoteId}`);

    if (response.data.error === 'insurance_in_process') {
      confirmAlert('Non è possibile modificare, vuoi che ricalcoliamo?', () => modifyData(), () => { }, 'Aviso!');
      caller.travelQuotesDataUpdated({
        addingQuote: false,
        loading: false,
      });
      return;
    }

    if (response.status !== 200) {
      generalAlertError('La quotazione non e stata rimossa! Riprova.');
      caller.travelQuotesDataUpdated({
        addingQuote: false,
      });
      return;
    }
    caller.travelQuotesDataUpdated({
      addingQuote: false,
    });

    callback();
  } catch (error) {
    generalAlertError('Errore Interno.');
    caller.travelQuotesDataUpdated({
      addingQuote: false,
    });
  }
};

export const resetTravelQuotes = () => {
  caller.travelQuotesReset();
};

export const updateTravelGuarantee = async (
  quoteId,
  coverages,
  requestToken,
) => {
  const res = await quotationsAxe.put(`/guarantees/travel/${quoteId}/update`, {
    coverages,
  });

  if (res.status === 200) {
    loadTravelQuotes(requestToken);
  }
};

/**
 * Commercial Quotes
 */

export const loadCommercialQuotes = async (requestToken) => {
  const res = await quotationsAxe.get(
    `/results/quotes/commercial/${requestToken}`,
  );

  caller.commercialQuotesDataUpdated({
    insuranceRequest: res.data.data,
    processingQuote: res.data.data.processingQuote,
    quotes: res.data.data.quotes,
    quotations: res.data.quotations,
    companyData: res.data.data.commercial_company,
    loading: false,
    failedQuotes: res.data.data.failedQuotes,
    commercial_company_report: res.data.data.commercial_company_report,
    risk_quotations: res.data.data.risk_quotations,
  });
};

export const resetCommercialQuotes = () => {
  caller.commercialQuotesReset();
};

// ? do we need this ?
// export const updateCommercialGuarantee = async (
//   quoteId,
//   coverages,
// ) => {
//   const res = await quotationsAxe.put(
//     `/guarantees/commercial/${quoteId}/update`,
//     {
//       coverages,
//     },
//   );

//   if (res.status === 200) {
//     console.log('success update commercial guarantee');
//     // todo right action here
//   }
// };

export const addQuoteCommercialToCart = async (quoteId, callback, modifyData, closeButton = null) => {
  caller.commercialQuotesDataUpdated({
    addingQuote: true,
  });
  try {
    const response = await axe.get(`/quotations/save/${quoteId}`);

    if (response.data.error === 'insurance_in_process') {
      if (closeButton !== null) {
        closeButton();
      }
      confirmAlert('Non è possibile modificare, vuoi che ricalcoliamo?', () => modifyData(), () => { }, 'Aviso!');
      caller.commercialQuotesDataUpdated({
        addingQuote: false,
        loading: false,
      });
      return;
    }

    if (response.status !== 200) {
      generalAlertError('La quotazione non e stata aggiunta! Riprova.');
      caller.commercialQuotesDataUpdated({
        addingQuote: false,
      });
      return;
    }
    caller.commercialQuotesDataUpdated({
      addingQuote: false,
    });

    callback();

    if (closeButton !== null) {
      closeButton();
    }
  } catch (error) {
    generalAlertError('Errore Interno.');
    caller.commercialQuotesDataUpdated({
      addingQuote: false,
    });
  }
};
export const removeQuoteCommercialFromCart = async (quoteId, callback, modifyData) => {
  caller.commercialQuotesDataUpdated({
    loading: true,
  });
  try {
    const response = await axe.get(`/quotations/remove/${quoteId}`);

    if (response.data.error === 'insurance_in_process') {
      confirmAlert('Non è possibile modificare, vuoi che ricalcoliamo?', () => modifyData(), () => { }, 'Aviso!');
      caller.commercialQuotesDataUpdated({
        addingQuote: false,
        loading: false,
      });
      return;
    }

    if (response.status !== 200) {
      generalAlertError('La quotazione non e stata rimossa! Riprova.');
      caller.commercialQuotesDataUpdated({
        loading: false,
      });
      return;
    }
    caller.commercialQuotesDataUpdated({
      loading: false,
    });

    callback();
  } catch (error) {
    generalAlertError('Errore Interno.');
    caller.commercialQuotesDataUpdated({
      loading: false,
    });
  }
};

export const updateQuotesData = (requestInfo, sortBy, quotes) => {
  const { quotations } = requestInfo;
  let companyIds = [];
  const sortedQuotes = [...quotations];
  let toSortQuotes = { ...quotes };

  quotations.forEach((quote) => {
    companyIds.push(quote.company_id);
  });

  companyIds = companyIds.filter((v, i, a) => a.indexOf(v) === i);

  const toUpdate = {};

  if (quotes === null) {
    toUpdate.quotations = null;
  }
  if (quotations.length === 0) {
    toUpdate.quotes = null;
    toUpdate.noQuotes = true;
  } else {
    if (sortBy === 'amount') {
      for (let x = 0; x < quotationTypes.length; x++) {
        const item = quotationTypes[x];
        let arr = [];
        if (item.id === 'res_civile' && toSortQuotes[item.id] && toSortQuotes[item.id] !== undefined) {
          arr = [...toSortQuotes[item.id]];
          arr.sort((a, b) => a.amount - b.amount);
          toSortQuotes = {
            [item.id]: arr,
          };
        }
      }
      sortedQuotes.sort((a, b) => a.amount - b.amount);
    }

    toUpdate.quotations = sortedQuotes;
    toUpdate.noQuotes = false;
    toUpdate.quotes = { ...quotes, res_civile: toSortQuotes.res_civile };
  }

  caller.commercialQuotesDataUpdated(toUpdate);

  const activeBrands = [1, 2];

  let clearTimer = false;

  if (companyIds.length === activeBrands.length) {
    clearTimer = true;
  }

  return clearTimer;
};
export const listRenewals = async (search) => {
  caller.listRenewalsPending();
  try {
    const res = await axe.get('/renewals/list', {
      params: search,
    });

    if (res.status !== 200) {
      caller.listRenewalsReject('Errore Interno');
      return;
    }
    caller.listRenewalsSuccess(res.data.data);
  } catch (error) {
    caller.listRenewalsReject('Errore Interno');
  }
};

export const resetRenewals = () => {
  caller.listRenewalsReset();
};

export const markQuotationForProccessing = async (quotationId, successCallback) => {
  const res = await axe.put(`/quotations/${quotationId}/process/select`);

  if (res.status !== 200) {
    generalAlertError('Errore interno');
    return;
  }

  generalAlertSuccessToast('Preventivo Salvato');
  successCallback();
};

export const tutelaLegaleQuotesGetQuotes = async (requestToken) => {
  const response = await quotationsAxe.get(
    `/results/quotes/tutela-legale/${requestToken}`,
  );

  if (response.status !== 200) {
    caller.tutelaLegaleQuotesDataUpdatedRejected(serverError);
    return;
  }

  caller.tutelaLegaleQuotesDataUpdated({
    loading: false,
    quoteId: response.data.data.quotations[0].id,
    userData: response.data.data,
    selling_point: response.data.data.selling_point,
    distance_token: response.data.data.distance_token,
    customer: response.data.data.customer,
    quotations: response.data.data.quotes,
    processingQuote: response.data.data.processingQuote,
    tutelaLegaleQuote: response.data.data.tutelaLegaleQuote,
    riskQuotations: response.data.data.risk_quotations,
    error: null,
  });
};

export const resetTutelaLegaleQuotes = () => {
  caller.tutelaLegaleQuotesReset();
};

export const addVehicleAmount = async (requestToken, collectData, value) => {
  caller.addVehicleAmountPending();

  try {
    const res = await axe.post(`/quote/add-extra-risks/${requestToken}`, { value });

    if (res.status !== 200) {
      caller.addVehicleAmountRejected(serverError);
    }

    caller.addVehicleAmountSuccess();
    collectData();
  } catch (error) {
    caller.addVehicleAmountRejected('Errore Interno');
  }
};

export const houseQuotesGetQuotes = async (requestToken) => {
  const response = await quotationsAxe.get(
    `/results/quotes/house/${requestToken}`,
  );

  if (response.status !== 200) {
    caller.houseQuotesDataUpdatedRejected(serverError);
    return;
  }

  caller.houseQuotesDataUpdated({
    loading: false,
    data: response.data,
    quoteId: response.data.data.quotations.id,
    insuranceRequest: response.data.data.quotations,
    selling_point: response.data.data.selling_point,
    distance_token: response.data.data.distance_token,
    customer: response.data.data.quotations.customer,
    quotations: response.data.data.quotations.quotes,
    processingQuote: response.data.data.processingQuote,
    riskQuotations: response.data.data.risk_quotations,
    error: null,
  });
};

export const resetHouseQuotes = () => {
  caller.houseQuotesReset();
};

export const addHouseQuoteToCart = async (quoteId, callback, modifyData, closeButton = null) => {
  caller.houseQuotesDataUpdated({
    addingQuote: true,
  });

  try {
    const response = await axe.get(`/quotations/save/${quoteId}`);

    if (response.data.error === 'insurance_in_process') {
      if (closeButton !== null) {
        closeButton();
      }

      confirmAlert('Non è possibile modificare, vuoi che ricalcoliamo?', () => modifyData(), () => { }, 'Aviso!');
      caller.houseQuotesDataUpdated({
        addingQuote: false,
        loading: false,
      });
      return;
    }

    if (response.status !== 200) {
      generalAlertError('La quotazione non e stata aggiunta! Riprova.');
      caller.houseQuotesDataUpdated({
        addingQuote: false,
      });
      return;
    }
    caller.houseQuotesDataUpdated({
      addingQuote: false,
    });

    callback();

    if (closeButton !== null) {
      closeButton();
    }
  } catch (error) {
    generalAlertError('Errore Interno.');
    caller.houseQuotesDataUpdated({
      addingQuote: false,
    });
  }
};

export const removeHouseQuoteFromCart = async (quoteId, callback, modifyData) => {
  caller.houseQuotesDataUpdated({
    addingQuote: true,
  });
  try {
    const response = await axe.get(`/quotations/remove/${quoteId}`);

    if (response.data.error === 'insurance_in_process') {
      confirmAlert('Non è possibile modificare, vuoi che ricalcoliamo?', () => modifyData(), () => { }, 'Aviso!');
      caller.houseQuotesDataUpdated({
        addingQuote: false,
        loading: false,
      });
      return;
    }

    if (response.status !== 200) {
      generalAlertError('La quotazione non e stata rimossa! Riprova.');
      caller.houseQuotesDataUpdated({
        addingQuote: false,
      });
      return;
    }
    caller.houseQuotesDataUpdated({
      addingQuote: false,
    });

    callback();
  } catch (error) {
    generalAlertError('Errore Interno.');
    caller.houseQuotesDataUpdated({
      addingQuote: false,
    });
  }
};

export const saluteQuotesGetQuotes = async (requestToken) => {
  const response = await quotationsAxe.get(
    `/results/quotes/salute/${requestToken}`,
  );

  if (response.status !== 200) {
    caller.saluteQuotesDataUpdatedRejected(serverError);
    return;
  }

  caller.saluteQuotesDataUpdated({
    loading: false,
    data: response.data,
    quoteId: response.data.data.quotations.id,
    insuranceRequest: response.data.data.quotations,
    selling_point: response.data.data.selling_point,
    distance_token: response.data.data.distance_token,
    customer: response.data.data.quotations.customer,
    quotations: response.data.data.quotations.quotes,
    processingQuote: response.data.data.processingQuote,
    riskQuotations: response.data.data.risk_quotations,
    toSelect: [],
    quote_id_to_add: null,
    error: null,
  });
};

export const resetSaluteQuotes = () => {
  caller.saluteQuotesReset();
};

export const addSaluteQuoteToCart = async (quoteId, callback, modifyData, closeButton = null, showModal = null, quote_id_to_add = null) => {
  caller.saluteQuotesDataUpdated({
    addingQuote: true,
  });

  try {
    const response = await axe.get(`/quotations/save/${quoteId}`);

    if (response.status === 400 && response.data.data.code === 'to_select') {
      if (closeButton !== null) {
        closeButton();
      }
      showModal(true);
      caller.saluteQuotesDataUpdated({
        loading: false,
        addingQuote: false,
        toSelect: response.data.data.toSelect,
        quote_id_to_add: response.data.data.quote_id_to_add,
      });
      return;
    }

    if (response.data.error === 'insurance_in_process') {
      if (closeButton !== null) {
        closeButton();
      }
      confirmAlert('Non è possibile modificare, vuoi che ricalcoliamo?', () => modifyData(), () => { }, 'Aviso!');
      caller.saluteQuotesDataUpdated({
        addingQuote: false,
        loading: false,
      });
      return;
    }

    if (response.status !== 200) {
      generalAlertError('La quotazione non e stata aggiunta! Riprova.');
      caller.saluteQuotesDataUpdated({
        addingQuote: false,
      });
      return;
    }

    if (quote_id_to_add !== null) {
      const res = await axe.get(`/quotations/save/${quote_id_to_add}`);

      if (res.data.error === 'insurance_in_process') {
        if (closeButton !== null) {
          closeButton();
        }
        confirmAlert('Non è possibile modificare, vuoi che ricalcoliamo?', () => modifyData(), () => { }, 'Aviso!');
        caller.saluteQuotesDataUpdated({
          addingQuote: false,
          loading: false,
        });
        return;
      }

      if (res.status !== 200) {
        generalAlertError('La quotazione non e stata aggiunta! Riprova.');
        caller.saluteQuotesDataUpdated({
          addingQuote: false,
        });
        return;
      }
      showModal(false);
    }

    caller.saluteQuotesDataUpdated({
      addingQuote: false,
    });

    callback();

    if (closeButton !== null) {
      closeButton();
    }
  } catch (error) {
    generalAlertError('Errore Interno.');
    caller.saluteQuotesDataUpdated({
      addingQuote: false,
    });
  }
};

export const removeSaluteQuoteFromCart = async (quoteId, callback, modifyData) => {
  caller.saluteQuotesDataUpdated({
    addingQuote: true,
  });
  try {
    const response = await axe.get(`/quotations/remove/${quoteId}`);

    if (response.data.error === 'insurance_in_process') {
      confirmAlert('Non è possibile modificare, vuoi che ricalcoliamo?', () => modifyData(), () => { }, 'Aviso!');
      caller.saluteQuotesDataUpdated({
        addingQuote: false,
        loading: false,
      });
      return;
    }

    if (response.status !== 200) {
      generalAlertError('La quotazione non e stata rimossa! Riprova.');
      caller.saluteQuotesDataUpdated({
        addingQuote: false,
      });
      return;
    }
    caller.saluteQuotesDataUpdated({
      addingQuote: false,
    });

    callback();
  } catch (error) {
    generalAlertError('Errore Interno.');
    caller.saluteQuotesDataUpdated({
      addingQuote: false,
    });
  }
};

// Ancillari Auto

export const ancillariAutoQuotesGetQuotes = async (requestToken) => {
  const response = await quotationsAxe.get(
    `/results/quotes/ancillari-auto/${requestToken}`,
  );

  if (response.status !== 200) {
    caller.ancillariAutoQuotesDataUpdated(serverError);
    return;
  }

  caller.ancillariAutoQuotesDataUpdated({
    loading: false,
    data: response.data,
    quoteId: response.data.data.quotations.id,
    insuranceRequest: response.data.data.quotations,
    selling_point: response.data.data.selling_point,
    distance_token: response.data.data.distance_token,
    customer: response.data.data.quotations.customer,
    quotations: response.data.data.quotations.quotes,
    processingQuote: response.data.data.processingQuote,
    riskQuotations: response.data.data.risk_quotations,
    vehicle: response.data.data.vehicle,
    error: null,
  });
};

export const resetAncillariAutoQuotes = () => {
  caller.ancillariAutoQuotesReset();
};

export const addAncillariAutoQuoteToCart = async (quoteId, callback, modifyData, closeButton = null) => {
  caller.ancillariAutoQuotesDataUpdated({
    addingQuote: true,
  });

  try {
    const response = await axe.get(`/quotations/save/${quoteId}`);

    if (response.data.error === 'insurance_in_process') {
      if (closeButton !== null) {
        closeButton();
      }

      confirmAlert('Non è possibile modificare, vuoi che ricalcoliamo?', () => modifyData(), () => { }, 'Aviso!');
      caller.ancillariAutoQuotesDataUpdated({
        addingQuote: false,
        loading: false,
      });
      return;
    }

    if (response.status !== 200) {
      if (response.status === 400 && response.data.data.error === 'can_not_add_cristalli') {
        generalAlertError(response.data.data.message);
      } else if (response.status === 400 && response.data.data.error === 'can_not_add_collisione') {
        generalAlertError(response.data.data.message);
      } else {
        generalAlertError('La quotazione non e stata aggiunta! Riprova.');
      }

      caller.ancillariAutoQuotesDataUpdated({
        addingQuote: false,
      });
      return;
    }
    caller.ancillariAutoQuotesDataUpdated({
      addingQuote: false,
    });

    callback();

    if (closeButton !== null) {
      closeButton();
    }
  } catch (error) {
    generalAlertError('Errore Interno.');
    caller.ancillariAutoQuotesDataUpdated({
      addingQuote: false,
    });
  }
};

export const removeAncillariAutoQuoteFromCart = async (quoteId, callback, modifyData) => {
  caller.ancillariAutoQuotesDataUpdated({
    addingQuote: true,
  });
  try {
    const response = await axe.get(`/quotations/remove/${quoteId}`);

    if (response.data.error === 'insurance_in_process') {
      confirmAlert('Non è possibile modificare, vuoi che ricalcoliamo?', () => modifyData(), () => { }, 'Aviso!');
      caller.ancillariAutoQuotesDataUpdated({
        addingQuote: false,
        loading: false,
      });
      return;
    }

    if (response.status !== 200) {
      generalAlertError('La quotazione non e stata rimossa! Riprova.');
      caller.ancillariAutoQuotesDataUpdated({
        addingQuote: false,
      });
      return;
    }
    caller.ancillariAutoQuotesDataUpdated({
      addingQuote: false,
    });

    callback();
  } catch (error) {
    generalAlertError('Errore Interno.');
    caller.ancillariAutoQuotesDataUpdated({
      addingQuote: false,
    });
  }
};
