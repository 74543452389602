import React, { useEffect, useState } from 'react';
import { FormInputRadio } from '../../../../common/formInputs/FormInputRadio';
import { questionarioCommercial } from '../questions';
import { flagResponse } from '../../../../common/constants';
import { Accordion } from '../../../../common/components/Accordion';

function LocationAndAmbient({ register, errorMessages, errors }) {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).some((key) => key.includes('location_and_structures'))) {
      setHasError(true);
    }
  }, [errors]);

  return (
    <Accordion title={questionarioCommercial[0].title} defaultOpen hasError={hasError} setHasError={setHasError}>
      <ul className="list-unstyled">
        {questionarioCommercial[0].questions.map((question, index) => (
          <li className="mb-2" key={index}>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <p className="mb-0 text-center">{question.question}</p>
              <FormInputRadio
                registration={register(question.input_name)}
                options={flagResponse}
                error={errorMessages(question.input_name)}
              />
            </div>
          </li>
        ))}
      </ul>
    </Accordion>
  );
}

export default LocationAndAmbient;
