import moment from 'moment';
import React from 'react';
import { stringToPrice } from '../../../common/constants';

function CompanyFinancialStatements({ commercial_report, dynamicHeaders, staticLabels }) {
  return (
    <>
      <h4 className="text-center mt-3">Bilanci Finanziari</h4>

      <div className="container mt-5 overflow-auto">
        <table className="table table-bordered">
          <thead className="thead-dark">
            <tr>
              <th />
              {commercial_report.commercial_company_financial_statements.map((item, index) => (
                <th key={index}>{moment(item.year_end_date).format('Y')}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dynamicHeaders
              .filter((header) => !['commercial_company_id', 'id', 'created_at', 'updated_at'].includes(header))
              .map((header) => (
                <tr key={header}>
                  <td>{staticLabels[header]}</td>
                  {commercial_report.commercial_company_financial_statements.map((item, index) => (
                    <td key={index}>{stringToPrice(item[header])}</td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default CompanyFinancialStatements;
