import React, { useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { FormInputText } from '../../../common/formInputs/FormInputText';
import { FormInputTextarea } from '../../../common/formInputs/FormInputTextarea';
import SimpleModal from '../../../common/components/elements/SimpleModal';
import { FormInputSelect } from '../../../common/formInputs/FormInputSelect';
import {
  branch_type,
  fabricated_options,
  flagResponse,
  number_of_floors_options,
  type_of_construction_options,
  value_of_electronic_equipment_options,
  year_of_construction_options,
} from '../../../common/constants';
import { FormInputRadio } from '../../../common/formInputs/FormInputRadio';
import { addCommercialBranch } from '../../../features/commercialReport/commercialReportActions';
import { FormInputPrice } from '../../../common/formInputs/FormInputPrice';

const schema = yup.object({
  address: yup.string().required('Address is required'),
  description: yup.string().required('Description is required'),
  branch_type: yup.string().required('Branch Type is required'),
  year_of_construction: yup
    .string()
    .required('Year of Construction is required'),
  number_of_floors: yup.string().required('Number of Floors is required'),
  type_of_construction: yup
    .string()
    .required('Type of Construction is required'),
  presence_of_alarm_system: yup
    .string()
    .required('Presence of Alarm System is required'),
  fabricated: yup.string().required('Fabricated is required'),
  manufactured_value: yup
    .string()
    .required('Manufactured Value is required')
    .matches(/^[1-9]\d*$/, 'Il numero deve essere maggiore di 0.'),
  content_value: yup
    .string()
    .required('Content Value is required')
    .matches(/^[1-9]\d*$/, 'Il numero deve essere maggiore di 0.'),
  value_of_electronic_equipment: yup
    .string()
    .required('Value of Electronic Equipment is required'),
});

function CompanyBranches({
  branchErrors,
  branchIsValid,
  branchRegister,
  answers,
  fetchData,
  vatNumber,
  setBranchValue,
}) {
  const [openModal, setOpenModal] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    setValue,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: {
      address: '',
      description: '',
      branch_type: '',
      year_of_construction: '',
      number_of_floors: '',
      type_of_construction: '',
      presence_of_alarm_system: '',
      fabricated: '',
      manufactured_value: '',
      content_value: '',
      value_of_electronic_equipment: '',
    },
  });

  const data = watch();

  const isValid = (name) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  const submitData = async () => {
    if (Object.keys(errors).length > 0) {
      return;
    }

    await addCommercialBranch(vatNumber, data);
    await fetchData();
  };

  const handleButtonClick = () => {
    setOpenModal(true);
  };

  return (
    <>
      <h4 className="text-center mt-3">Filiali di Aziendali Commerciali</h4>
      <div>
        <button
          className="btn btn-success border py-1 px-4 px-md-5"
          onClick={handleButtonClick}
        >
          Aggiungi Nuova
        </button>
        {openModal && (
          <SimpleModal title="Branch Dati" setIsOpen={setOpenModal}>
            <form onSubmit={handleSubmit(submitData)}>
              <div
                className="row py-4 overflow-auto"
                style={{ maxHeight: '500px' }}
              >
                <div className="col-12 col-md-6">
                  <FormInputText
                    label="Indirizzo"
                    placeholder="Indirizzo"
                    name="address"
                    registration={register('address')}
                    error={errors.address?.message}
                    valid={isValid('address')}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <FormInputSelect
                    label="Tipo di branch"
                    placeholder="-Seleziona-"
                    options={branch_type}
                    name="branch_type"
                    registration={register('branch_type')}
                    error={errors.branch_type?.message}
                    valid={isValid('branch_type')}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <FormInputText
                    label="Descrizione"
                    placeholder="Descrizione"
                    name="description"
                    registration={register('description')}
                    error={errors.description?.message}
                    valid={isValid('description')}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <FormInputSelect
                    label="Anno di costruzione"
                    placeholder="-Seleziona-"
                    options={year_of_construction_options}
                    name="Description"
                    registration={register('year_of_construction')}
                    error={errors.year_of_construction?.message}
                    valid={isValid('year_of_construction')}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <FormInputSelect
                    label="Numero piani"
                    placeholder="-Seleziona-"
                    options={number_of_floors_options}
                    name="number_of_floors"
                    registration={register('number_of_floors')}
                    error={errors.number_of_floors?.message}
                    valid={isValid('number_of_floors')}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <FormInputSelect
                    label="Tipo di costruzione"
                    placeholder="-Seleziona-"
                    options={type_of_construction_options}
                    name="type_of_construction"
                    registration={register('type_of_construction')}
                    error={errors.type_of_construction?.message}
                    valid={isValid('type_of_construction')}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <FormInputRadio
                    label="Presenza di impianto d'allarme"
                    options={flagResponse}
                    name="presence_of_alarm_system"
                    registration={register('presence_of_alarm_system')}
                    error={errors.presence_of_alarm_system?.message}
                    valid={isValid('presence_of_alarm_system')}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <FormInputSelect
                    label="Fabricato"
                    placeholder="-Seleziona-"
                    options={fabricated_options}
                    name="fabricated"
                    registration={register('fabricated')}
                    error={errors.fabricated?.message}
                    valid={isValid('fabricated')}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <FormInputPrice
                    label="Valore fabbricato"
                    placeholder="Valore Fabricato"
                    value={data.manufactured_value}
                    name="manufactured_value"
                    onChange={(value) => setValue('manufactured_value', value, {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,
                    })}
                    error={errors.manufactured_value?.message}
                    valid={isValid('manufactured_value')}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <FormInputPrice
                    label="Valore contenuto"
                    placeholder="Valore contenuto"
                    value={data.content_value}
                    name="content_value"
                    onChange={(value) => setValue('content_value', value, {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,
                    })}
                    error={errors.content_value?.message}
                    valid={isValid('content_value')}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <FormInputSelect
                    label="Valore apparecchiature elettroniche"
                    placeholder="-Seleziona-"
                    options={value_of_electronic_equipment_options}
                    name="value_of_electronic_equipment"
                    registration={register('value_of_electronic_equipment')}
                    error={errors.value_of_electronic_equipment?.message}
                    valid={isValid('value_of_electronic_equipment')}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    type="Submit"
                    className="btn btn-success btn-base rounded-4 px-5 fw-bold"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </SimpleModal>
        )}
      </div>

      {(answers.company_branches == null
        || answers.company_branches.length == 0) && (
        <div className="alert alert-danger mt-2" role="alert">
          Nessuna filiale aziendale commerciale aggiunta, dovrebbe essercene
          almeno una
        </div>
      )}

      <div className="row mt-5">
        {answers.company_branches.map((branch, index) => (
          <div className="col-12 col-md-6 mb-4" key={index}>
            <div className="card border">
              <div className="card-body">
                <FormInputText
                  placeholder="Indirizzo"
                  label="Indirizzo"
                  error={
                    branchErrors.company_branches?.[index]?.address?.message
                  }
                  valid={branchIsValid(`company_branches.${index}.address`)}
                  registration={branchRegister(
                    `company_branches.${index}.address`,
                  )}
                />

                <FormInputTextarea
                  placeholder="Descrizione"
                  label="Descrizione"
                  error={
                    branchErrors.company_branches?.[index]?.description?.message
                  }
                  valid={branchIsValid(`company_branches.${index}.description`)}
                  registration={branchRegister(
                    `company_branches.${index}.description`,
                  )}
                />
                <FormInputSelect
                  label="Anno di costruzione"
                  placeholder="-Seleziona-"
                  options={year_of_construction_options}
                  name="Description"
                  error={
                    branchErrors.company_branches?.[index]?.year_of_construction
                      ?.message
                  }
                  valid={branchIsValid(
                    `company_branches.${index}.year_of_construction`,
                  )}
                  registration={branchRegister(
                    `company_branches.${index}.year_of_construction`,
                  )}
                />
                <FormInputSelect
                  label="Numero piani"
                  placeholder="-Seleziona-"
                  options={number_of_floors_options}
                  name="number_of_floors"
                  error={
                    branchErrors.company_branches?.[index]?.number_of_floors
                      ?.message
                  }
                  valid={branchIsValid(
                    `company_branches.${index}.number_of_floors`,
                  )}
                  registration={branchRegister(
                    `company_branches.${index}.number_of_floors`,
                  )}
                />
                <FormInputSelect
                  label="Tipo di costruzione"
                  placeholder="-Seleziona-"
                  options={type_of_construction_options}
                  name="type_of_construction"
                  error={
                    branchErrors.company_branches?.[index]?.type_of_construction
                      ?.message
                  }
                  valid={branchIsValid(
                    `company_branches.${index}.type_of_construction`,
                  )}
                  registration={branchRegister(
                    `company_branches.${index}.type_of_construction`,
                  )}
                />
                <FormInputRadio
                  label="Presenza di impianto d'allarme"
                  options={flagResponse}
                  name="presence_of_alarm_system"
                  error={
                    branchErrors.company_branches?.[index]
                      ?.presence_of_alarm_system?.message
                  }
                  valid={branchIsValid(
                    `company_branches.${index}.presence_of_alarm_system`,
                  )}
                  registration={branchRegister(
                    `company_branches.${index}.presence_of_alarm_system`,
                  )}
                />
                <FormInputSelect
                  label="Fabricato"
                  placeholder="-Seleziona-"
                  options={fabricated_options}
                  name="fabricated"
                  error={
                    branchErrors.company_branches?.[index]?.fabricated?.message
                  }
                  valid={branchIsValid(`company_branches.${index}.fabricated`)}
                  registration={branchRegister(
                    `company_branches.${index}.fabricated`,
                  )}
                />
                <FormInputPrice
                  label="Valore fabbricato"
                  placeholder="Valore Fabricato"
                  value={answers.company_branches[index].manufactured_value}
                  name="manufactured_value"
                  onChange={(value) => setBranchValue(
                    `company_branches.${index}.manufactured_value`,
                    value,
                    {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,
                    },
                  )}
                  error={
                    branchErrors.company_branches?.[index]?.manufactured_value
                      ?.message
                  }
                  valid={branchIsValid(
                    `company_branches.${index}.manufactured_value`,
                  )}
                />

                <FormInputPrice
                  label="Valore contenuto"
                  placeholder="Valore contenuto"
                  value={answers.company_branches[index].content_value}
                  name="content_value"
                  onChange={(value) => setBranchValue(
                    `company_branches.${index}.content_value`,
                    value,
                    {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,
                    },
                  )}
                  error={
                    branchErrors.company_branches?.[index]?.content_value
                      ?.message
                  }
                  valid={branchIsValid(
                    `company_branches.${index}.content_value`,
                  )}
                />
                <FormInputSelect
                  label="Valore apparecchiature elettroniche"
                  placeholder="-Seleziona-"
                  options={value_of_electronic_equipment_options}
                  name="value_of_electronic_equipment"
                  error={
                    branchErrors.company_branches?.[index]
                      ?.value_of_electronic_equipment?.message
                  }
                  valid={branchIsValid(
                    `company_branches.${index}.value_of_electronic_equipment`,
                  )}
                  registration={branchRegister(
                    `company_branches.${index}.value_of_electronic_equipment`,
                  )}
                />
                <p className="fw-bold text-center">Branch Types</p>
                {branch.branch_types.map((type, x) => (
                  <div key={x}>
                    <FormInputText
                      placeholder="Name"
                      label="Name"
                      value={type.name}
                      error={branchErrors.name?.message}
                      valid={branchIsValid(
                        `company_branches.${index}.branch_types.${x}.name`,
                      )}
                      registration={branchRegister(
                        `company_branches.${index}.branch_types.${x}.name`,
                      )}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default CompanyBranches;
