import { ProgressBarStyles } from './ProgressBar.styles';

export default function ProgressBar({ activeStep, steps, onlyActiveText = false }) {
  const totalVisibleSteps = 4;
  let start = Math.max(0, activeStep - 2);
  const end = Math.min(steps.length, start + totalVisibleSteps);

  // Adjust start if there are fewer next steps than previous steps
  if (end - start < totalVisibleSteps) {
    start = Math.max(0, end - totalVisibleSteps);
  }

  const visibleSteps = steps.slice(start, end);

  return (
    <ProgressBarStyles>
      <div className="d-flex stepsProgressBar">
        {visibleSteps.map((step, index) => {
          const actualIndex = start + index;
          let completed = false;
          if ((activeStep === actualIndex + 1) || (activeStep > actualIndex + 1)) {
            completed = true;
          }
          return (
            <div key={step.toString()} className={`p-step ${completed ? 'completed' : ''}`}>
              {onlyActiveText && activeStep === actualIndex + 1 && (
                <span>{step}</span>
              )}
              {!onlyActiveText && (
                <>
                  <span className="indicator">
                    {actualIndex + 1}
                  </span>
                  <span>
                    {'. '}
                    {step}
                  </span>
                </>
              )}
            </div>
          );
        })}
      </div>
    </ProgressBarStyles>
  );
}
