import moment from 'moment';
import React from 'react';
import {
  getExtraCommercialQuoteTypeName,
  getQuotationTypeName,
} from '../../constants';

function CompletedQuoteScreenInfoCommercial({ rc_quotation, risk_quotations }) {
  return (
    <>
      <div className="col-12 col-md-6">
        <div className="card border mb-2 polizze-emessa-status-step-three">
          <div className="card-header bg-secondary text-white">
            <h2 className="h3 mb-0 card-title">Dati Responsabilita Civile</h2>
          </div>
          <div className="card-body">
            {rc_quotation.company_id != 16 ? (
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <b>Compagnia: </b>
                  {' '}
                  {rc_quotation.company.name}
                </li>
                <li className="list-group-item">
                  <b>Stato:</b>
                  {' '}
                  {rc_quotation.status === 7 && 'Polizza Emmesa'}
                </li>
                <li className="list-group-item">
                  <b>Data Decorrenza:</b>
                  {' '}
                  {moment(rc_quotation.insurance_request.policy_effective_date)
                    .toDate()
                    .toLocaleDateString('it-IT')}
                </li>
                <li className="list-group-item">
                  <b>Frequenza Pagamento:</b>
                  {' '}
                  {rc_quotation.payment_frequency === 1
                    ? 'Annuo'
                    : 'Semestrale'}
                </li>
                <li className="list-group-item">
                  <b>Pagato:</b>
                  {' '}
                  {rc_quotation.amount_paid === null
                    ? '0'
                    : rc_quotation.amount_paid.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Da Pagare:</b>
                  {' '}
                  {rc_quotation.amount_paid === rc_quotation.amount
                    ? '0'
                    : (
                      Math.ceil(rc_quotation.amount * 100) / 100
                        - rc_quotation.amount_paid
                    ).toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Prossimo Pagamento:</b>
                  {' '}
                  {moment(rc_quotation.insurance_request.policy_effective_date)
                    .add(
                      Math.floor(365 / rc_quotation.payment_frequency),
                      'days',
                    )
                    .toDate()
                    .toLocaleDateString('it-IT')}
                </li>
                <li className="list-group-item">
                  <b>Premio:</b>
                  {' '}
                  {rc_quotation.amount.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Provvigioni:</b>
                  {' '}
                  {rc_quotation.commision.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Brokeraggio:</b>
                  {' '}
                  {rc_quotation.brokerage_amount.toFixed(2)}
                  {' '}
                  &euro;
                </li>
                {/* {rc_quotation.quotation_services.length > 0 && (
                  <li className="list-group-item">
                    <b>Servizio:</b>
                    {' '}
                    {rc_quotation.quotation_services[0].amount_gross.toFixed(2)}
                    {' '}
                    &euro;
                  </li>
                )} */}
                {/* <li className="list-group-item">
                  <b>Totale:</b>
                  {' '}
                  {(
                    rc_quotation.amount
                    + rc_quotation.brokerage_amount
                    + (rc_quotation.quotation_services.length > 0
                      ? rc_quotation.quotation_services[0].amount_gross
                      : 0)
                  ).toFixed(2)}
                  {' '}
                  &euro;
                </li> */}
              </ul>
            ) : (
              ''
            )}
            {rc_quotation.company_id == 16 ? (
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <b>Compagnia: </b>
                  {' '}
                  {rc_quotation.company.name}
                </li>
                <li className="list-group-item">
                  <b>Stato:</b>
                  {' '}
                  {rc_quotation.status === 7 && 'Polizza Emmesa'}
                </li>
                <li className="list-group-item">
                  <b>Data Decorrenza:</b>
                  {' '}
                  {moment(rc_quotation.insurance_request.policy_effective_date)
                    .toDate()
                    .toLocaleDateString('it-IT')}
                </li>
                <li className="list-group-item">
                  <b>Polizza Pagata:</b>
                  {' '}
                  SI
                </li>
                <li className="list-group-item">
                  <b>Totale:</b>
                  {' '}
                  {rc_quotation.amount.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Provvigioni:</b>
                  {' '}
                  {rc_quotation.commision.toLocaleString()}
                  {' '}
                  &euro;
                </li>
                {/* {rc_quotation.quotation_services.length > 0 && (
                  <li className="list-group-item">
                    <b>Servizio:</b>
                    {' '}
                    {rc_quotation.quotation_services[0].amount_gross.toFixed(2)}
                    {' '}
                    &euro;
                  </li>
                )} */}
                {/* <li className="list-group-item">
                  <b>Totale:</b>
                  {' '}
                  {(
                    rc_quotation.amount
                    + rc_quotation.brokerage_amount
                    + (rc_quotation.quotation_services.length > 0
                      ? rc_quotation.quotation_services[0].amount_gross
                      : 0)
                  ).toFixed(2)}
                  {' '}
                  &euro;
                </li> */}
              </ul>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>

      {risk_quotations.map((risk_quote, index) => (
        <div className="col-12 col-xl-6" key={index}>
          <div className="card border mb-2 polizze-emessa-status-step-three">
            <div className="card-header bg-secondary text-white">
              <h2 className="h3 mb-0 card-title">
                {getQuotationTypeName(risk_quote.quote_type) === ''
                  ? getExtraCommercialQuoteTypeName(risk_quote.quote_type)
                  : getQuotationTypeName(risk_quote.quote_type)}
              </h2>
            </div>
            <div className="card-body">
              <ul className="list-group list-group-flush">
                <li className="list-group-item">
                  <b>Compagnia: </b>
                  {risk_quote.company.name}
                </li>
                <li className="list-group-item">
                  <b>Stato:</b>
                  {' '}
                  {risk_quote.status === 7 && 'Polizza Emmesa'}
                </li>
                <li className="list-group-item">
                  <b>Data Decorrenza:</b>
                  {' '}
                  {moment(risk_quote.insurance_request.policy_effective_date)
                    .toDate()
                    .toLocaleDateString('it-IT')}
                </li>
                <li className="list-group-item">
                  <b>Frequenza Pagamento:</b>
                  {' '}
                  {risk_quote.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}
                </li>
                <li className="list-group-item">
                  <b>Pagato:</b>
                  {' '}
                  {risk_quote.amount_paid === null
                    ? '0.00'
                    : parseFloat(risk_quote.amount_paid).toFixed(2)}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Da Pagare:</b>
                  {' '}
                  {risk_quote.amount_paid === risk_quote.amount
                    ? '0.00 €'
                    : (
                      Math.ceil(risk_quote.amount * 100) / 100
                        - risk_quote.amount_paid
                    ).toFixed(2)}
                </li>
                <li className="list-group-item">
                  <b>Prossimo Pagamento:</b>
                  {' '}
                  {moment(risk_quote.insurance_request.policy_effective_date)
                    .add(Math.floor(365 / risk_quote.payment_frequency), 'days')
                    .toDate()
                    .toLocaleDateString('it-IT')}
                </li>
                <li className="list-group-item">
                  <b>Premio:</b>
                  {' '}
                  {parseFloat(risk_quote.amount).toFixed(2)}
                  {' '}
                  &euro;
                </li>
                <li className="list-group-item">
                  <b>Totale:</b>
                  {' '}
                  {risk_quote.amount.toFixed(2)}
                  {' '}
                  &euro;
                </li>
              </ul>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}

export default CompletedQuoteScreenInfoCommercial;
