import React, { useState, useEffect } from 'react';
import { questionarioCommercial } from '../questions';
import { FormInputSelect } from '../../../../common/formInputs/FormInputSelect';
import { flagResponse, questionInProcessiProdotti } from '../../../../common/constants';
import { FormInputRadio } from '../../../../common/formInputs/FormInputRadio';
import { Accordion } from '../../../../common/components/Accordion';

function ProcessProdotti({ register, errorMessages, errors }) {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (
      Object.keys(errors).some((key) => key.includes('product_and_its'))
    ) {
      setHasError(true);
    }
  }, [errors]);

  return (
    <Accordion title={questionarioCommercial[6].title} defaultOpen={false} hasError={hasError} setHasError={setHasError}>
      <ul className="list-unstyled">
        {questionarioCommercial[6].questions.map((question, index) => (
          <li className="mb-2" key={index}>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <p className="mb-0 text-center">{question.question}</p>
              {question.input_type === 'select' ? (
                <FormInputSelect
                  value=""
                  registration={register(`${question.input_name}`)}
                  placeholder="-Seleziona-"
                  options={questionInProcessiProdotti}
                  formGroupClassName="w-50"
                  error={errorMessages(question.input_name)}
                />
              ) : (
                <FormInputRadio
                  options={flagResponse}
                  registration={register(`${question.input_name}`)}
                  error={errorMessages(question.input_name)}
                />
              )}
            </div>
          </li>
        ))}
      </ul>
    </Accordion>

  );
}

export default ProcessProdotti;
