import * as yup from 'yup';

export const validationsSchema = yup.object({
  activity_code: yup.string().nullable(),
  activity_description: yup.string().nullable(),
  activity_industry_sector: yup.string().nullable(),
  alter_summary_address: yup.string().nullable(),
  alter_summary_employees_nr: yup
    .number('Deve essere un numero')
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
    .required('Il campo è obbligatorio. Inserisci numero di dipendenti!')
    .test(
      'is-number-and-positive',
      'Deve essere un numero e maggiore di 0',
      (value) => value === null || (typeof value === 'number' && value > 0),
    ),
  alter_summary_incorporation_date: yup.string().nullable(),
  alter_summary_province: yup.string().nullable(),
  alter_summary_share_capital: yup.string().nullable(),
  business_name: yup.string().nullable(),
  company_id: yup.string().nullable(),
  company_number: yup.string().nullable(),
  company_registration_number: yup.string().nullable(),
  company_status: yup.string().nullable(),
  credit_rating_common_desc: yup.string().nullable(),
  credit_rating_common_value: yup.string().nullable(),
  credit_rating_limit_value: yup.string().nullable(),
  summary_extra_has_cigs_events: yup.string().nullable(),
  summary_extra_has_company_insolvency: yup.string().nullable(),
  summary_extra_has_insolvency: yup.string().nullable(),
  summary_extra_has_prejudicials: yup.string().nullable(),
  summary_extra_has_protesti: yup.string().nullable(),
  summary_extra_has_severe_prejudicials: yup.string().nullable(),
  summary_extra_has_severe_protesti: yup.string().nullable(),
  turnover_value: yup.string().nullable(),
  phone: yup
    .string()
    .required('Il campo è obbligatorio. Inserisci numero di telefono!')
    .matches(/^[3]{1}[0-9]{8,9}$/, 'Il numero non è corretto'),
  email: yup
    .string()
    .required('Il campo è obbligatorio. Inserisci Email!')
    .email('Deve essere un email valido! Rimuovi spazi vuoti!'),
  company_commentaries: yup.array().nullable(),
  share_holders: yup.array().nullable(),
  directors: yup.array().nullable(),
  extended_group_structure: yup.array().nullable(),
  company_branches: yup.array().of(
    yup.object().shape({
      address: yup.string().required('Indirizzo è obbligatorio'),
      description: yup.string().required('Descrizione è obbligatoria'),
      year_of_construction: yup.string().required('Anno di costruzione è obbligatorio'),
      number_of_floors: yup.string().required('Numero di piani è obbligatorio'),
      type_of_construction: yup.string().required('Tipo di costruzione è obbligatorio'),
      presence_of_alarm_system: yup.string().required('Presenza di sistema di allarme è obbligatoria'),
      fabricated: yup.string().required('Fabbricato è obbligatorio'),
      manufactured_value: yup
        .string()
        .required('Valore di fabbricazione è obbligatorio')
        .matches(/^[1-9]\d*$/, 'Il numero deve essere maggiore di 0.'),
      content_value: yup
        .string()
        .required('Valore del contenuto è obbligatorio')
        .matches(/^[1-9]\d*$/, 'Il numero deve essere maggiore di 0.'),
      value_of_electronic_equipment: yup.string().required('Valore delle apparecchiature elettroniche è obbligatorio'),
    }),
  ).required('Il campo è obbligatorio.').min(1, 'Il campo non può essere vuoto.'),
  company_indicators: yup.array().nullable(),
});

export const commercialCompanyDefaultValues = {
  activity_code: '',
  activity_description: '',
  activity_industry_sector: '',
  alter_summary_address: '',
  alter_summary_employees_nr: '',
  alter_summary_incorporation_date: '',
  alter_summary_province: '',
  alter_summary_share_capital: '',
  business_name: '',
  company_id: '',
  company_number: '',
  company_registration_number: '',
  company_status: '',
  credit_rating_common_desc: '',
  credit_rating_common_value: '',
  credit_rating_limit_value: '',
  summary_extra_has_cigs_events: '',
  summary_extra_has_company_insolvency: '',
  summary_extra_has_insolvency: '',
  summary_extra_has_prejudicials: '',
  summary_extra_has_protesti: '',
  summary_extra_has_severe_prejudicials: '',
  summary_extra_has_severe_protesti: '',
  email: '',
  phone: '',
  turnover_value: '',
  company_commentaries: [],
  share_holders: [],
  directors: [],
  extended_group_structure: [],
  company_branches: [],
  company_indicators: [],
};

export const answersFieldsBySteps = [
  [
    'activity_code',
    'activity_description',
    'activity_industry_sector',
    'alter_summary_address',
    'alter_summary_employees_nr',
    'alter_summary_incorporation_date',
    'alter_summary_province',
    'alter_summary_share_capital',
    'business_name',
    'company_id',
    'company_number',
    'company_registration_number',
    'company_status',
    'credit_rating_common_desc',
    'credit_rating_common_value',
    'credit_rating_limit_value',
    'summary_extra_has_cigs_events',
    'summary_extra_has_company_insolvency',
    'summary_extra_has_insolvency',
    'summary_extra_has_prejudicials',
    'summary_extra_has_protesti',
    'summary_extra_has_severe_prejudicials',
    'summary_extra_has_severe_protesti',
    'turnover_value',
  ],
  [
    'company_commentaries',
  ],
  [
    'share_holders',
  ],
  [
    'directors',
  ],
  [
    'extended_group_structure',
  ],
  [
    'commercial_company_financial_statements',
  ],
  [
    'company_branches',
  ],
  [
    'company_indicators',
  ],
  ['email', 'phone'],
];
