import React, { useEffect, useState } from 'react';

export function Accordion({
  title, children, defaultOpen = false, hasError = false, setHasError,
}) {
  const [open, setOpen] = useState(defaultOpen);

  useEffect(() => {
    if (hasError) {
      setOpen(true);
    }
  }, [hasError]);

  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id={`heading-${title}`}>
        <button
          className={`accordion-button ${open ? '' : 'collapsed'}`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapse-${title}`}
          aria-expanded={open}
          aria-controls={`collapse-${title}`}
          onClick={() => {
            setOpen(!open);
            setHasError(false);
          }}
        >
          {title}
        </button>
      </h2>
      <div
        id={`collapse-${title}`}
        className={`accordion-collapse collapse ${open ? 'show' : ''}`}
        aria-labelledby={`heading-${title}`}
        data-bs-parent="#accordionExample"
      >
        <div className="accordion-body">
          {children}
        </div>
      </div>
    </div>
  );
}
