import React from 'react';
import { FormInputText } from '../../../common/formInputs/FormInputText';

function CompanyIndicators({
  errors, isValid, register, answers,
}) {
  return (
    <>
      <h4 className="text-center mt-3">Indicatori di Società Commerciali</h4>
      {answers.company_indicators.map((indicator, index) => (
        <div className="card border mt-5" key={index}>
          <div className="card-body mb-4">
            <div className="row">
              <div className="col-12">
                <FormInputText
                  placeholder="Description"
                  label="Descrizione"
                  error={errors.description?.message}
                  valid={isValid(`company_indicators.${index}.description`)}
                  registration={register(`company_indicators.${index}.description`)}
                />
                <FormInputText
                  placeholder="Value Description"
                  label="Descrizione del Valore"
                  error={errors.value_description?.message}
                  valid={isValid(`company_indicators.${index}.value_description`)}
                  registration={register(`company_indicators.${index}.value_description`)}
                />

                <p className="fw-bold text-center">Categorie</p>
                <div className="row">
                  {indicator.categories.map((category, x) => (
                    <div className="col-12 col-md-6 mt-3" key={x}>
                      <div className="card border">
                        <div className="card-body">
                          <FormInputText
                            placeholder="Description"
                            label="Descrizione"
                            error={errors.description?.message}
                            valid={isValid(`company_indicators.${index}.categories.${x}.description`)}
                            registration={register(`company_indicators.${index}.categories.${x}.description`)}

                          />
                          <FormInputText
                            placeholder="Value Description"
                            label="Descrizione del Valore"
                            error={errors.value_description?.message}
                            valid={isValid(`company_indicators.${index}.categories.${x}.value_description`)}
                            registration={register(`company_indicators.${index}.categories.${x}.value_description`)}

                          />
                        </div>

                      </div>
                    </div>
                  ))}

                </div>

              </div>
            </div>
          </div>
        </div>
      ))}

    </>
  );
}

export default CompanyIndicators;
